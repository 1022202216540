import React from "react";

// import careers from '../../static/careers/careers-cropped-2.jpg';
// import internship from '../../static/careers/internship.jpg';

import Layout from "../../../components/Layout/Layout";

const Article03012021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              ENSURING BUSINESS CONTINUITY AND DISASTER RECOVERY WITH ORACLE
              OBJECT STORAGE
            </h1>
            <p>
              The amount of data we produce every day is truly mind-boggling.
              2.5 quintillion bytes of data are generated every day at our
              current pace, and this pace is only accelerating with the
              advancement of the Internet of Things (IoT). In the last two years
              alone, over 90 percent of the world's data has been generated!
              Effectively using and managing your data is critical to your
              business. Choosing the right tools can speed up development many
              times over, and the wrong tools can wreak havoc. S&T Mold invites
              you to take part in the MUK partner webinar. During the webinar,
              Oracle data management experts will share their experiences, best
              practices for implementation and use, and how to use Oracle
              Database Options to:
            </p>
            <p className="bold">Agenda:</p>
            <p>
              11:00 - 11:10 - Welcome speech. Dmitry Olkhovsky, ES Manager S&T
              Mold SRL; Dmitry Mischuk, BDM, ERC
            </p>
            <p>
              11:10 - 12:00 - Oracle Cloud Infrastructure Object Storage is a
              high-performance web-scale storage platform that delivers reliable
              and cost-effective data longevity. Alexander Manchuk, Enterprise
              Architect, ERC
            </p>
            <p className="bold">Webinar details:</p>
            <p>Date: 17 February 2021</p>
            <p>Time: 11:00 – 12:00</p>
            <p>Language: RU</p>
            <p className="bold">
              Registration{" "}
              <a href="https://edu.erc.ua/ru/partner-events/vebinar-obespechenie-nepryvnosti-i-katastrofoustojchivosti-biznesa-s-obektnym-xranilishhem-oracle/">
                Link
              </a>
            </p>
            <p>We look forward to your participation!</p>
            <p>Your S&T Mold team</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Article03012021;
